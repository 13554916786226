window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap-sass');
    require('popper.js');
    require('jasny-bootstrap/dist/js/jasny-bootstrap');
    require('nouislider');

} catch (e) {}


require('./perfect-scrollbar.jquery.min.js');
require('./light-bootstrap-dashboard');
require('es6-promise/auto');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import Bus from './bus';

// Catch axios errors and automatically handle them
window.axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    switch (error.response.status) {
        case 401:
            document.getElementById('logout-form').submit();
            window.location.assign('/login');
            break;

        case 500:
            Bus.$emit('flashMessage', {
                level: 'danger',
                message: 'There was a problem with our server. Please try again.'
            });
            break;
    }

    return Promise.reject(error);
});